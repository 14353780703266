import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Binder from '../components/Binder';
import './HomePage.css';

const HomePage = ({ setSupertype, setType, resetListings }) => {
    const [listingsGroups, setListingsGroups] = useState([]); // Array of arrays, each representing a Binder
    const [featuredListings, setFeaturedListings] = useState([]);
    const [holidayListings, setHolidayListings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [featuredLoading, setFeaturedLoading] = useState(true);
    const [holidayLoading, setHolidayLoading] = useState(true);
    const [error, setError] = useState(null);
    const [supertype, setSupertypeState] = useState('');
    const [type, setTypeState] = useState('');
    const [page, setPage] = useState(3); // Start the "See More" button at page 3
    const [count] = useState(6); // Page size for listings
    const [totalListings, setTotalListings] = useState(0); // Total active listings
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchHolidayListings = async () => {
        try {
            const url = `${apiBaseUrl}/listings?psaOrderNumber=24398108&count=16`;

            //url += `&includeSet=false&`
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Ensures cookies are sent with cross-origin requests
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const result = await response.json();
            setHolidayListings(result.listings);
        } catch (error) {
            setError(error.message);
        } finally {
            setHolidayLoading(false);
        }
    };

    const fetchFeaturedListings = async () => {
        try {
            const url = `${apiBaseUrl}/listings?featured=1`;

            //url += `&includeSet=false&`
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Ensures cookies are sent with cross-origin requests
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const result = await response.json();
            setFeaturedListings(result.listings);
        } catch (error) {
            setError(error.message);
        } finally {
            setFeaturedLoading(false);
        }
    };

    const fetchTotalListings = async () => {
        try {
            const url = `${apiBaseUrl}/listings/count`;

            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Ensures cookies are sent with cross-origin requests
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch total listings: ${response.statusText}`);
            }

            const result = await response.json();
            setTotalListings(result.Count); // Update total listings count
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchListings = async (currentPage) => {
        setLoading(true);
    
        try {
            let url = `${apiBaseUrl}/listings?`;
            //url += `includeSet=false&`
            url += `page=${currentPage}&count=${count}&`;
    
            if (supertype) {
                url += `supertype=${supertype}&`;
            }
            if (type) {
                url += `type=${type}&`;
            }
    
            url = url.slice(0, -1); // Remove trailing '&' or '?' if exists
    
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Ensures cookies are sent with cross-origin requests
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
    
            const result = await response.json();
    
            if (result.listings.length > 0) {
                setListingsGroups((prevGroups) => {
                    const newListings = result.listings.filter(
                        (listing) =>
                            !prevGroups.flat().some((existingListing) => existingListing.id === listing.id)
                    );
                    return [...prevGroups, newListings];
                });
    
                // Only increment the page if there are results
                setPage((prevPage) => (prevPage !== null ? prevPage + 1 : null));
            } else {
                setPage(null); // No more pages to fetch
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    

    useEffect(() => {
        const fetchAll = async () => {
            await fetchFeaturedListings();
            await fetchHolidayListings();
            await fetchListings(1); // Fetch the first page
            await fetchTotalListings(); // Fetch total listings count from the new endpoint
            setPage(2); // Start the next page at 3 explicitly after initial fetch
        };
    
        setListingsGroups([]); // Clear existing binders when supertype/type changes
        setPage(3); // Reset page explicitly to avoid race conditions
        fetchAll(); // Begin fetching featured and regular listings
    }, [supertype, type]);
    

    const handleSetSupertype = (newSupertype) => {
        setSupertypeState(newSupertype);
        setTypeState('');
        resetListings(true, newSupertype); // Pass newSupertype to resetListings
    };

    const handleSetType = (newType) => {
        setTypeState(newType);
        setSupertypeState('');
        resetListings(true, newType); // Pass newType to resetListings
    };

    if (error) return <p>Error loading cards: {error}</p>;

    return (
        <Layout>
            <Header setSupertype={handleSetSupertype} setType={handleSetType} resetListings={fetchListings} />

            {/* Gradies Banner */}
            <div className="gradies-banner">
                <p>Gradies</p>
                <p>PSA-Graded Pokémon Cards</p>
            </div>

            <h1>Featured</h1>

            {/* Featured Listings Binder */}
            <div className="featured-binder-container">
                {featuredLoading ? (
                    <p>Loading featured cards...</p>
                ) : (
                    <Binder listings={featuredListings} />
                )}
            </div>
            <h2>Pokémon Holiday Calendar 2024</h2>

            {/* Holiday Listings Binder */}
            <div className="holiday-binder-container">
                {holidayLoading ? (
                    <p>Loading holiday cards...</p>
                ) : (
                    <Binder listings={holidayListings} />
                )}
            </div>

            <h2>({totalListings}) Listings</h2>

            {/* Main Listings Binders */}
            {listingsGroups.map((group, index) => (
                <Binder key={index} listings={group} />
            ))}

            {/* See More Button */}
            {page !== null && !loading && (
                <button className="see-more-button" onClick={() => fetchListings(page)}>
                    See More
                </button>
            )}

            {loading && <p>Loading more cards...</p>}
        </Layout>
    );
};

export default HomePage;
